import { createAction, props } from "@ngrx/store";
import { ReadyGGAdData } from "@readygg/ng-api";

export const getAdsByTags = createAction(
    '[Ads] Get Ads By Tags',
    props<{ tags: string[] }>()
);

export const getAdsByTagsSuccess = createAction(
    '[Ads] Get Ads By Tags Success',
    props<{ ads: ReadyGGAdData[] }>()
);

export const getAdsByTagsFailure = createAction(
    '[Ads] Get Ads By Tags Failure',
    props<{ error: any }>()
);

export const getAdsToDisplay = createAction(
    '[Ads] Get Ads To Display',
);

export const getAdsToDisplaySuccess = createAction(
    '[Ads] Get Ads To Display Success',
    props<{ ads: ReadyGGAdData[] }>()
);

export const getAdsToDisplayFailure = createAction(
    '[Ads] Get Ads To Display Failure',
    props<{ error: any }>()
);

export const AdsActions = {
    getAdsByTags,
    getAdsByTagsSuccess,
    getAdsByTagsFailure,
    getAdsToDisplay,
    getAdsToDisplaySuccess,
    getAdsToDisplayFailure
}
