<div class="card flex justify-content-center w-full">
  <p-autoComplete
    [(ngModel)]="selectedGame"
    [suggestions]="filteredGames"
    (completeMethod)="filterGame($event)"
    field="name"
    placeholder="Search games"
    [forceSelection]="true"
  >
    <ng-template let-game pTemplate="item">
      <div class="flex align-items-center gap-2">
        <img [src]="game.iconUrl" alt="{{ game.name }}" style="width: 18px" />
        <div>{{ game.name }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
