import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Game } from '../../data/services/game-service/interfaces/game.interface';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, FormsModule, AutoCompleteModule],
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
})
export class SearchComponent implements OnInit {
  selectedGame: any;
  filteredGames: Game[] = [];

  @Input() games: Game[] = [];
  @Output() filteredGamesChange = new EventEmitter<Game[]>();

  constructor() {}

  ngOnInit() {
    this.filteredGames = [...this.games];
  }

  filterGame(event: any) {
    const query = event.query.toLocaleLowerCase();

    if (query.trim() === '') {
      this.filteredGames = [...this.games];
    } else {
      this.filteredGames = this.games.filter((game) =>
        game.name.toLocaleLowerCase().startsWith(query)
      );
    }

    this.filteredGamesChange.emit(this.filteredGames);
  }
}
